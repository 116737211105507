import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './../../App.js';
import serverApiHandler from './../apiHandler/serverApiHandler.js';
import Store from './Store.js';
import Banner from './../common/Banner.js';
import Loader from './../common/Loader.js';
import './store.css';
import { publishEvent } from './../../utilities/ManageEvents.js';
import mobileBanner from './../../assets/J24-Mobile-Banner.jpg';
import desktopBanner from './../../assets/J24-Desktop-Banner.jpg';
import noStore from './../../assets/no-store.svg';

// const banner = 'https://jtsuperclubimg.s3-ap-southeast-1.amazonaws.com/pitch-banner-option2.jpg';

const StoreList = ({ stores, message, showDistance, action }) => {
    return (
        <div>
            <div className='store-selector-header'>
                <span className='med-text'>{message}</span> ({stores.length}{' '}
                Stores)
            </div>
            <div className='store-list'>
                {stores.map((i, index) => (
                    <Store
                        st={i}
                        key={index}
                        showDistance={showDistance}
                        storeSelected={action}
                    />
                ))}
            </div>
        </div>
    );
};

export default function StoreSelector(props) {
    const [locPermission, setLocPermission] = useState(false);
    const [nearByStores, setNearByStores] = useState([]);
    const [otherStores, setOtherStores] = useState([]);
    const [filteredNearByStores, setFilteredNearByStores] = useState([]);
    const [filteredOtherStores, setFilteredOtherStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState(null);
    const context = useContext(AppContext);
    const store = context.store.get;
    const { showWebView } = context;

    useEffect(() => {
        // var ref = document.referrer;
        // console.log(ref);
        // if (ref.includes('/n/')) {
        //     console.log('hey');
        //     window.location.replace(`https://j24.store${}`);
        // }

        context.header.set({ type: 'CITY_NAME', text: '' });

        if (!store) {
            if (navigator.geolocation) {
                publishEvent('ON_LOAD_LOCATION_PERMISSION');
                navigator.geolocation.getCurrentPosition(
                    fetchWithPosition,
                    showAll,
                    { timeout: 3000 }
                );
            } else {
                showAll();
            }
        } else {
            window.location.assign('/');
        }
    }, []);

    const handleStores = (stores) => {
        let nearBy = [];
        let others = [];
        for (let i in stores) {
            if (stores[i].distance < 5) {
                nearBy.push(stores[i]);
            } else {
                others.push(stores[i]);
            }
        }
        setNearByStores(nearBy);
        setFilteredNearByStores(nearBy);
        setOtherStores(others);
        setFilteredOtherStores(others);
    };

    const getStores = (params) => {
        setLoading(true);
        if (params) {
            setLocPermission(true);
        } else {
            setLocPermission(false);
        }
        let startTime = new Date().getTime();
        serverApiHandler
            .getAllStoreToShow(params)
            .then((resp) => {
                let ttms = new Date().getTime() - startTime;

                resp.stores.forEach((st) => {
                    if(st.businessId === 'BZID-1304437246'){
                         st.storeName = 'J24 Selvams Essentials (Now called as J24 Pranav Essentials)';
                    }
                })

                handleStores(resp.stores);
                publishEvent(
                    'ON_LOAD_STORE_LIST',
                    { stores: resp.stores },
                    ttms
                );
            })
            .catch((error) => {})
            .finally((resp) => {
                setLoading(false);
            });
    };

    const fetchWithPosition = (position) => {
        publishEvent('ON_SUBMIT_ACCEPT_LOCATION_PERMISSION');
        let lat = position.coords.latitude;
        let long = position.coords.longitude;
        let params = '?latitude=' + lat + '&longitude=' + long;
        getStores(params);
    };

    const showAll = () => {
        publishEvent('ON_SUBMIT_DENY_LOCATION_PERMISSION');
        getStores('');
    };

    const storeSelected = (store) => {
        publishEvent('ON_SUBMIT_STORE', {
            bzid: store.businessId,
            sn: store.storeName,
        });
        context.store.set(store);
        localStorage.removeItem('cart');
        localStorage.setItem('selectedStore', JSON.stringify(store));
        window.location.assign('/');
    };

    const searchStore = (e) => {
        let qry = e.target.value;
        setQuery(qry);
        if (!qry) {
            setFilteredNearByStores(nearByStores);
            setFilteredOtherStores(otherStores);
        } else {
            setFilteredNearByStores(
                nearByStores.filter(
                    (st) =>
                        st.storeName
                            .toLowerCase()
                            .includes(qry.toLowerCase()) ||
                        st.storeShortAddress
                            .toLowerCase()
                            .includes(qry.toLowerCase())
                )
            );
            setFilteredOtherStores(
                otherStores.filter(
                    (st) =>
                        st.storeName
                            .toLowerCase()
                            .includes(qry.toLowerCase()) ||
                        st.storeShortAddress
                            .toLowerCase()
                            .includes(qry.toLowerCase())
                )
            );
        }
    };

    return (
        <div>
            <div className='store-selector-wrapper'>
                <div style={{ position: 'relative' }}>
                    <Banner
                        banner={showWebView ? desktopBanner : mobileBanner}
                    />
                    <div className={'store-search-box'}>
                        <div className='search-box-wrapper search-box-desktop'>
                            <div className='search-box'>
                                <input
                                    type='text'
                                    placeholder='Search for J24 Store'
                                    className='search-text'
                                    autoFocus={true}
                                    value={query}
                                    onChange={searchStore}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'relative' }}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            {locPermission ? (
                                <>
                                    {filteredNearByStores.length > 0 ? (
                                        <StoreList
                                            stores={filteredNearByStores}
                                            message={'Nearby J24 Stores'}
                                            action={storeSelected}
                                            showDistance={true}
                                        />
                                    ) : (
                                        <div className='no-store-box'>
                                            <img src={noStore} />
                                            <div>
                                                No J24 store in your area.
                                            </div>
                                        </div>
                                    )}
                                    {filteredOtherStores.length > 0 ? (
                                        <div
                                            className={
                                                filteredNearByStores.length > 0
                                                    ? 'top-fifty'
                                                    : ''
                                            }
                                        >
                                            <StoreList
                                                stores={filteredOtherStores}
                                                message={'Other J24 Stores'}
                                                action={storeSelected}
                                                showDistance={true}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <StoreList
                                        stores={filteredNearByStores}
                                        message={'J24 Stores'}
                                        action={storeSelected}
                                        showDistance={false}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
