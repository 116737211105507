import React, { useState, useEffect, useCallback } from 'react';


function Footer() {
    return (<div style={{
        position:'fixed',
        bottom:'0px',
        backgroundColor:'#62B04D',
        width:'100%',
        alignItems:'center',
        alignContent:'center',
        alignSelf:'center',
        textAlign:'center',
        color:'white',
    }}>
        <p>Copyright © 2024 J24.Store</p>
        <p> <a style={{
            textDecoration:'none',
            color:'white'
        }} href='https://j24.store/privacy-policy.html'>Privacy Policy</a> | <a style={{
            textDecoration:'none',
            color:'white'
        }} href='https://j24.store/terms-of-use.html' >Terms of use</a> |  <a style={{
            textDecoration:'none',
            color:'white'
        }} href='https://j24.store/return-cancellation-policy.html' >Refund & Cancellation Policy</a></p>
    </div>)
}

export default Footer